import React from 'react'
//import { Link } from 'gatsby'
//import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./achievements.module.css"

const AchievementsPage = () => (
	<Layout>
	<SEO title="実績" />
		<div className={styles.container}>
			<h1>主な実績</h1>
			<h2>サーバ構築・運用実績</h2>
			<h3>多数の拠点を展開する中小企業が業務で利用する各種サーバの提供</h3>
			<p>
			本社のオンプレミス環境におけるウェブサーバ、メールサーバ、ネームサーバの構築・運用
			</p>

			<h3>100以上の独自ドメインをホスティングするネームサーバの構築・運用</h3>
			<p>
			オンプレミス環境での運用を経た後、災害やセキュリティリスク等を考慮し、クラウド環境での運用にシフト
			</p>

			<h2>ネットワーク構築・運用実績</h2>
			<h3>業務ネットワークの構築・運用</h3>
			<p>
			宿泊施設を営む中小企業の事務所ネットワークや宿泊者向けネットワーク、小規模の教育施設における施設内ネットワークの構築など。それぞれ、有線LANおよび無線LAN (Wi-Fi) で提供。
			</p>

			<h3>イベント会場での安定したインターネット接続環境の提供</h3>
			<p>
			100人規模の参加者が集まるイベント会場での来場者向けインターネット接続環境と無線LAN (Wi-Fi) 環境を構築
			</p>

			<h2>情報セキュリティ関連実績</h2>
			<h3>学生を対象とした情報セキュリティ教育の実施</h3>
			<p>
			ITインフラの運用やアプリケーション、ネットワークに関連する情報セキュリティを題材とした課外授業や講習を実施
			</p>
			<h3>情報セキュリティ啓発活動</h3>
			<p>
			情報セキュリティにまつわるコラムを内閣サイバーセキュリティセンター (通称 NISC) に寄稿。また、医療従事者の集まる情報学会において、情報セキュリティの啓発を行う講演を実施。
			</p>
		</div>
	</Layout>
)

export default AchievementsPage

/* [EOF] */
